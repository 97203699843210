<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">정산정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          정산정보 표
        </caption>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">정산상태</th>
            <td colspan="3">
              <div class="row_pack">
                <Radio
                  :dataList="CALCULATE_STATE_RADIO_LIST"
                  name="radioPrice"
                  :selectedId.sync="model.accountYn"
                  @onChange="onChangeCalculateState"
                />
                <p class="desc_tip tc_red">
                  <span class="ico_account ico_exclam_r" />완료를 선택하면 더이상 정산을 할 수
                  없습니다.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">청구기간 <sup>*</sup></th>
            <td>
              <div class="box_picker">
                <flat-pickr
                  v-model="model.accountStartDate"
                  class="txt_time"
                  :config="flatpickrConfigMonthDraftFrom"
                  dateFormat="Y-M"
                  placeholder="날짜선택"
                  name="date"
                  :disabled="flatpickrConfigMonthDraftFrom.disabled"
                  @on-change="listenToOnChangeDayDraftFrom"
                  @on-close="listenToOnCloseDay"
                />
                <span class="ico_account ico_calendar" />
                <span class="txt_bar">~</span>
                <flat-pickr
                  v-model="model.accountEndDate"
                  class="txt_time"
                  :config="flatpickrConfigMonthDraftTo"
                  placeholder="날짜선택"
                  name="date"
                  :disabled="flatpickrConfigMonthDraftTo.disabled"
                  @on-change="listenToOnChangeDay"
                  @on-close="listenToOnCloseDay"
                />
                <span class="ico_account ico_calendar" />
              </div>
            </td>
            <th scope="row">청구유형</th>
            <td>
              <Radio
                :dataList="CHARGE_TYPE_RADIO_LIST"
                name="radioChargeType"
                :selectedId.sync="model.accountType"
                :isDisabled="chargeTypeRadioDisabled"
              />
            </td>
          </tr>
          <tr v-if="model.purchasePaymentList.length > 0 && isShowPurchasePaymentList">
            <th scope="row">월별 청구 금액</th>
            <td colspan="3">
              <ul class="list_monthly list_monthly_write">
                <DraftPurchasePaymentListLine
                  v-for="(item, index) in model.purchasePaymentList"
                  :key="index"
                  :itemData="item"
                  :isDisabled="item.disabled"
                />
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import DraftPurchasePaymentListLine from "./DraftPurchasePaymentListLine";
import Radio from "@/_approval/components/common/radio/Radio";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import { getAccountDates, getMaxDates } from "@/utils/dateUtils";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

export default {
  name: "DraftPurchasePayment",
  components: { CommOpt, CommInpSearch, DraftPurchasePaymentListLine, Radio },
  mixins: [CommFlatpickrMixin, CommLayerMixin],
  props: {
    model: Object,
  },
  data() {
    return {
      CALCULATE_STATE_RADIO_LIST: [
        { code: "false", name: "미완료", id: "calculateUnCompleteStatus" },
        { code: "true", name: "완료", id: "calculateCompleteStatus" },
      ],
      CHARGE_TYPE_RADIO_LIST: [
        { code: "R", name: "정기" },
        { code: "O", name: "1회 또는 수시" },
      ],
      flatpickrConfigMonthDraftFrom: { disabled: false },
      flatpickrConfigMonthDraftTo: { disabled: false },
      chargeTypeRadioDisabled: false,
      isShowPurchasePaymentList: true,
    };
  },
  computed: {
    accountType() {
      return this.model.accountType;
    },
  },
  watch: {
    accountType() {
      this.model.setUnitformRate();
    },
  },
  beforeMount() {
    this.flatpickrConfigMonthDraftFrom = Object.assign(
      this.flatpickrConfigMonthDraftFrom,
      this.flatpickrConfigMonth,
    );

    this.flatpickrConfigMonthDraftTo = Object.assign(
      this.flatpickrConfigMonthDraftTo,
      this.flatpickrConfigMonth,
    );
  },
  mounted() {
    this.setDefaultMonth();
  },
  methods: {
    listenToOnChangeDay(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      // v-model 에서 넘어오는 값이 간헐적으로 이상한값(전에 선택했던 값)으로 넘어오는 현상이 있어 아래로 대체함.
      const selectedEndDate = `${fp.currentYear}-${fp.currentMonth + 1}`;
      // draftTo
      if (this.model.accountStartDate && selectedEndDate) {
        const res = this.model.makeMonthlyPriceListByDate(
          this.model.accountStartDate,
          selectedEndDate,
          // this.model.accountEndDate,
        );

        if (res) {
          this._showLayer({ type: "alert", content: res });
        }
      }
      // else {
      //   this.model.makeDefaultMonthlyPriceList();
      // }
    },
    listenToOnChangeDayDraftFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigMonthDraftTo.minDate = dStr;

      // 최대 5년 제한.
      if (dObj && dObj[0]) {
        const date = dObj[0];
        const copiedDate = new Date(date.getTime());

        copiedDate.setFullYear(copiedDate.getFullYear() + 10);
        const max = getMaxDates(copiedDate);
        //copiedDate.setFullYear(copiedDate.getFullYear() + 5);
        //const maxDate = copiedDate + 5

        // this.flatpickrConfigMonthDraftTo.maxDate = copiedDate;
        this.flatpickrConfigMonthDraftTo = {
          maxDate: max,
        };

        const copiedDate2 = new Date(date.getTime());
        copiedDate2.setFullYear(date.getFullYear());
        // 청구기간은 기본 12개월
        copiedDate2.setMonth(copiedDate2.getMonth() + 11);
        this.model.accountEndDate = copiedDate2;
      }
    },
    listenToOnCloseDay(dObj, dStr, fp, dayElem) {},
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onChangeCalculateState(changedState, originState) {
      if (changedState === "true") {
        this._showLayer(
          {
            type: "confirm",
            content: "완료상태로 변경 시 기존 입력된 정산정보는 사라집니다.",
          },
          () => this.setMonthInitial(changedState, originState),
          this.setAccountYn,
        );

        return;
      }

      this.flatpickrConfigMonthDraftFrom.disabled = changedState === "true";
      this.flatpickrConfigMonthDraftTo.disabled = changedState === "true";
      this.chargeTypeRadioDisabled = changedState === "true";
      this.isShowPurchasePaymentList = changedState === "false";
      this.model.purchasePaymentList = [];

      this.model.accountStartDate = null;
      this.model.accountEndDate = null;

      if (changedState === "false") {
        this.setDefaultMonth();
      }
    },
    setMonthInitial(changedState, originState) {
      this.flatpickrConfigMonthDraftFrom.disabled = changedState === "true";
      this.flatpickrConfigMonthDraftTo.disabled = changedState === "true";
      this.chargeTypeRadioDisabled = changedState === "true";
      this.isShowPurchasePaymentList = changedState === "false";
      this.model.purchasePaymentList = [];

      this.model.accountStartDate = null;
      this.model.accountEndDate = null;

      if (changedState === "false") {
        this.setDefaultMonth();
      }
    },
    setAccountYn() {
      this.model.accountYn = false;
    },
    setDefaultMonth() {
      const accountDates = getAccountDates();
      this.model.accountStartDate = accountDates.accountStartDate;
      this.model.accountEndDate = accountDates.accountEndDate;
    },
  },
};
</script>
